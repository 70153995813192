<template>
	<div class="helpcenter">
		<div class="wrap">
			<div class="helpcenter__title">С чего начать</div>
		</div>
		<span class="helpcenter__text"
			>Это краткое руководство, как привлечь больше гостей в жильё и научиться
			сдавать его после регистрации в приложении.</span
		>
		<ul class="list-help">
			<li>
				1. После регистрации было бы неплохо заполнить профиль. Для этого
				перейдите в профиль -> анкета и заполните 2 раздела "Расскажите о себе"
				и "Род деятельности". Далее загрузите аватар нажав на иконку фотографии
				в кружочке в самом верху того же раздела профиль -> анкета. Заполните
				свои ФИО, дату рождения, укажите пол. Если хотите, можете указать
				электронную почту, на неё вы сможете получать уведомления, если
				захотите. После того, как вы заполнили анкету, вы должны подтвердить
				свою личность, что бы мы могли удостовериться, что вы истинный владелец
				аккаунта или если случится форс-мажор или преступление, у нас будут
				достоверные сведения, что вы владелец жилья, так же, подтверждённые
				личности хозяев жилья - это обязательное условие нашего сервиса Теремок.
				Этим мы повышаем лояльность гостей и их доверие, по этому в нашем
				приложении намного больше гостей и выше их уровень доверия к хозяевам
				жилья. Что бы подтвердить личность, вы должны по шагам выполнить ряд
				действий, нажав на кнопку "подтвердить личность" в разделе профиль ->
				анкета.
			</li>
			<li>
				2. После того, как вы отправили заявку на подтверждение личности, можно
				приступить к созданию объявления жилья. Для этого перейдите в раздел
				"профиль" и из него перейдите в раздел "моё жильё". Далее справа вверху
				нажмите кнопку "добавить" и заполните все обязательные поля объявления.
				После этого, нажмите на кнопку "опубликовать объявление". Далее наши
				специалисты технической поддержки проверят ваши данные по объявлению и
				примут решение о публикации объявления, или отклонении его и указании
				причины "почему" объявление не было опубликовано, что бы вы могли
				исправить недочёты.
			</li>
			<li>
				3. Если вам удалось опубликовать объявление - вам остаётся только ждать
				поступающих заявок на бронирования вашего жилья. Помните, что чем больше
				у вас объявлений о сдаче жилья, тем больше вероятность, что у вас
				забронируют жильё. А так же, это зависит от качества фотографий,
				описания и названия объявления, удобства расположения, вашего ранга
				профиля, рейтинга самого аккаунта и отзывов и многого другого.
			</li>
			<li>
				4. После получения заявки вы принимаете или отклоняете заявку на
				бронирование.
			</li>
			<li>
				5. В случае принятия, вы ожидаете гостей у себя в жилье в назначенную
				дату, первую дату бронирования.
			</li>
			<li>
				6. После завершения аренды гостями и завершении бронирования, заявка
				изменит статус с "активное бронирование" на статус "бронирование
				завершено". У вас появится возможность оценить гостей, где будут
				предложены варианты оценок в пятибальной шкале и текста. Для этого у вас
				появится уведомление о предложении оставить отзыв в разделе профиль ->
				отзывы.
			</li>
			<li>
				Сдавайте качественное жильё и подходите ответственно к сдаче жилья.
			</li>
			<li>Желаем Вам порядочных и добрых гостей в Вашем Теремке!</li>
		</ul>
	</div>
</template>
